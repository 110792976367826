.container{
    :global{
        .ant-tabs-nav{
            background: transparent !important;
        }

        .ant-tabs-nav{
            &::before{
                border-bottom: none !important;
            }
        }

        .ant-tabs-tab-btn{
            line-height: 30px;
        }

        .ant-tabs-tab-active{
            font-size: 18px;
        }
        
        .ant-tabs-nav-more{
            display: none;
        }
    }
}