.container{
    position: relative;
   
    .skeleton{
        position: absolute;
        display: flex;
        flex-direction: column;
    }

    .hidden{
        opacity: 0;
    }

    .imgBox{
        border-radius: 15px;
        overflow: hidden;
        position: relative;

        .img{
            object-fit:cover;
            aspect-ratio: 16 / 9;
            transition: transform 1s ease; /* 添加过渡效果 */
            user-select: none;
            opacity: .8;


           &:hover{
            transform: scale(1.5); /* 鼠标移上时图片放大 */
           }
        }

        .des{
            position: absolute;
            left: 0;
            bottom:  0;
            margin-bottom: 0;

            background-color: rgba(255, 255, 255,.2);
            backdrop-filter:blur(15px);

            width: 100%;
            // height: 40px;
            line-height: 25px;
            padding: 0 10px;
            color: rgb(99, 98, 97);

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            transition: transform 1s ease; /* 添加过渡效果 */

            &:hover{
                color: rgb(0, 0, 0);;
                white-space: wrap;
                overflow: hidden;
                max-height: 80px;
                overflow: auto;
            }
        }
    }

    .title{
        padding: 5px 0;
        margin: 0 auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }


}