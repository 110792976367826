body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}
body ::-webkit-scrollbar {
  width: 0px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.container {
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(45deg, #93a5cf 0%, #e4efe9 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}
