.swiperBox{
    width: 100%;
    max-height: 330px;
     opacity: .8;
     overflow: hidden;

    position: relative;

    .text{
        position: absolute;
        right: 3%;
        bottom: 5%;
        // transform: translate(-50%,-50%);

        user-select: none;

        font-size:20px;
        color: white;

        -webkit-text-stroke: 1px #545554; /*文字描边*/
        -webkit-text-fill-color: transparent; /*设置文字的填充颜色*/
    }
    .img{
        display: block;
        width: 100%;
        height: 100%;

        object-fit:cover;

        aspect-ratio: 3.5 / 1;
       
    }
}
