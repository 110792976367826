.textCenter{
    text-align: center;

    .box{
        padding: 10px;
        border-radius: 18px;
        background-color: rgba(255,255,255,.2);
        backdrop-filter:blur(25px);
        transition: transform 0.3s ease; /* 添加过渡效果 */
        cursor:pointer;
        
        &:hover{
            background-color: rgba(255,255,255,.4);
            transform: scale(1.05); /* 鼠标移上时图片放大 */
        }
    }
}



